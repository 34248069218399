import React, { useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Container, Form } from 'react-bootstrap';

import snackbarType from '../../enum/snackbar_type';
import templateType from '../../enum/email_template_type';
import FormsSchema from '../../schema/forms';

import useMessageService from '../../service/message_service';

import EveryHero from '../global/every_hero';
import PageLead from '../global/page_lead';

import './contact_us.scss';

import imgHeroContactUs from '../../assets/images/imgHappyDog2.jpg';

export default function ContactUs(props) {
    const { showSnack } = props;
    const messageService = useMessageService();
    const recaptchaRef = useRef();

    const { contactUsForm } = FormsSchema;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting }
    } = useForm({
        resolver: yupResolver(contactUsForm)
    });

    const reCaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
    const reCaptchaAction = 'submit'; // Define the reCAPTCHA action

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaSiteKey}`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.grecaptcha.enterprise.ready(() => {
                // No render call needed here
            });
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onSubmit = async (data) => {

        try {
            const token = await window.grecaptcha.enterprise.execute(reCaptchaSiteKey, { action: reCaptchaAction });

           if (!token) {
               showSnack(snackbarType.BAD_MESSAGE, 'reCAPTCHA verification failed.');
               return;
           }

           const messageData = {
               visitor_name: data.full_name,
               visitor_email: data.email,
               visitor_phone: data.phone,
               visitor_message: data.message,
               recipient: import.meta.env.VITE_SENDGRID_TO_EMAIL,
               recipient_name: import.meta.env.VITE_SENDGRID_TO_NAME,
               recipient_org_name: import.meta.env.VITE_SENDGRID_TO_ORG_NAME,
               template_name: templateType.CONTACT_US,
               recaptcha_action: 'submit',
               recaptcha_token: token,
               recaptcha_enterprise: true,  // use reCaptcha Entperprise
           };

           const res = await messageService.sendMessage(messageData);

           if (res && res.status === 200) {
               showSnack(snackbarType.GOOD_MESSAGE, 'Your message has been sent!');
               reset();
           } else {
               showSnack(snackbarType.BAD_MESSAGE, 'Failed to send message. Please try again.');
           }

       } catch (error) {
           console.error('reCAPTCHA execution error:', error);
           showSnack(snackbarType.BAD_MESSAGE, 'An error occurred. Please try again.');
       }
    };

    return (
        <>
            <EveryHero image={imgHeroContactUs} title='Contact Us' />
            <PageLead
                text={<>Contact us <span className='page-lead-text-script'>today</span> to start the conversation on your new website!</>}
                subtext={<>Let&apos;s set up a video call where you can get to know us, tell us about your vision and ask questions. There&apos;s no time like the present!</>}
            />
            <Container className='max-width-xl'>
                <div className='main-contact-form'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='full_name'>Full Name<span className='text-danger'> *</span></Form.Label>
                            <Form.Control
                                id='full_name'
                                type='text'
                                {...register('full_name')}
                                className={`${errors.full_name ? 'is-invalid' : ''}`}
                            />
                            {errors.full_name && <span className='main-contact-form-input-error text-danger'>{errors.full_name.message}</span>}
                        </Form.Group>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='email'>Email Address<span className='text-danger'> *</span></Form.Label>
                            <Form.Control
                                id='email'
                                type='text'
                                {...register('email')}
                                className={`${errors.email ? 'is-invalid' : ''}`}
                            />
                            {errors.email && <span className='main-contact-form-input-error text-danger'>{errors.email.message}</span>}
                        </Form.Group>
                        <Form.Group className='main-contact-form-input'>
                            <Form.Label htmlFor='phone'>Phone Number</Form.Label>
                            <Form.Control
                                id='phone'
                                type='text'
                                {...register('phone')}
                            />
                        </Form.Group>
                        <Form.Group className='main-contact-form-input mb-3'>
                            <Form.Label htmlFor='message'>Message Text</Form.Label>
                            <Form.Control as='textarea' rows={5}
                                id='message'
                                type='text'
                                {...register('message')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <div ref={recaptchaRef} className='mb-3' />
                        </Form.Group>
                        <div className='main-contact-disclaimer'>
                            * Required <br />
                            EveryDVM never sells or shares your information
                        </div>
                        <Button variant='primary' type='submit' disabled={isSubmitting}>
                            SUBMIT
                        </Button>
                    </Form>
                </div>
            </Container>
        </>
    );
};